import { Link, useLocation } from "react-router-dom";
import "./_NavMenu.css";
import NavMenuLink from "./_NavMenuLink";

export default function NavMenu(props){
    const {isMenuOpen} = props;
    const location = useLocation();

    return (
        <div id="NavMenu" className="nav-menu d-flex flex-column justify-content-evenly p-2">
            <NavMenuLink
                path= "/"
                name= "Home"
                icon= "bi bi-house-fill"
                // image= "/img/icono_menu_corp.png"
                location = {location.pathname}
                isMenuOpen = {isMenuOpen}
            />
            <NavMenuLink
                path= "/region"
                name= "Región"
                image= "/img/icono_menu_oficio_servicio.png"
                location = {location.pathname}
                isMenuOpen = {isMenuOpen}
            />
            <NavMenuLink
                path= "/corporativo"
                name= "Corporativo"
                image= "/img/icono_menu_corp.png"
                location = {location.pathname}
                isMenuOpen = {isMenuOpen}
            />
            {/* <NavMenuLink
                path= "/admin"
                name= "Admin"
                image= "/img/icono_menu_gestion.png"
                location = {location.pathname}
                isMenuOpen = {isMenuOpen}
            /> */}
            <NavMenuLink
                path= "/buscador"
                name= "Buscador"
                icon= "bi bi-search"
                // image= "/img/icono_menu_corp.png"
                location= {location.pathname}
                isMenuOpen= {isMenuOpen}
            />
        </div>
    );
}