export default class Common{
    #id;
    #descripcion;
    #activo;

    constructor(entity){
        this.#id = entity.id;
        this.#descripcion = entity.descripcion;
        this.#activo = entity.activo;
    }

    get id(){
        return this.#id;
    }

    get descripcion(){
        return this.#descripcion;
    }

    get activo(){
        return this.#activo;
    }
}