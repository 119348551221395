import { useEffect, useState } from "react";
import FiltroColumna from "./FiltroColumna";
import "./List.css"

export default function MultiList(props){
    const {name, entities, selected, toggleSelected, ...rest} = props;

    const [legend, setLegend] = useState('');

    useEffect(() => {
        switch (selected.size) {
            case 0:
                setLegend('');
                break;
            case 1:
                setLegend('1 seleccionada')
                break
            case entities.length:
                setLegend('Todas seleccionadas')
                break;
            default:
                setLegend(selected.size.toString() + ' seleccionadas')
                break;
        }
    }, [selected]);

    const handleChange = (id) => {
        toggleSelected(id);
    }

    const handleClick = (e, id) => {
        e.stopPropagation();
        handleChange(id);
    }

    const renderList = () =>{
        const lista = [];
        entities.forEach(entity => {
            lista.push(
                <li key={name + '_' + entity.id.toString()} onClick={(e) => e.stopPropagation()}>
                    <div className="row g-0 list-item">
                        <div className="col col-auto px-2">
                            <input
                                id={name + '_' + entity.id.toString()}
                                type="checkbox"
                                checked={selected.has(entity.id)}
                                onChange={(e) => handleChange(entity.id)}
                            ></input>
                        </div>
                        <div className="col pe-2" onClick={(e) => handleClick(e, entity.id)}>
                            {entity.descripcion}
                        </div>
                    </div>
                </li>
            )
        });

        return lista;
    }

    return (
        <FiltroColumna title={name} {...rest}>
            <div className="dropdown full-width">
                <button className="dropdown-selector row g-0 full-width" type="button" data-bs-toggle="dropdown">
                    <div className="col pe-2 text-start dropdown-selector-text">
                        <span>{legend}</span>
                    </div>
                    <div className="col col-auto">
                        <i className="arrow down"></i>
                    </div>
                </button>
                <ul className="dropdown-menu dropdown-menu-right dropdown-list e2">
                    {renderList()}
                </ul>
            </div>
        </FiltroColumna>
    )
}