import React, { useContext } from "react";

const AppContext = React.createContext({
    token: null,
    jwt: null
});

export const AppContextProvider = AppContext.Provider;
export const useAppContext = () => {
    const context = useContext(AppContext);
    return context;
}
