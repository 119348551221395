import Modal from 'bootstrap/js/dist/modal';

export function ModalElement(props){
    const {modalId, title, primaryMessage = "Aceptar", onPrimary = () => {}, body = '', showCancelar = true} = props;

    const handleClick = () => {
        onPrimary();
        hideModal(modalId);
    }

    const renderCancelar = () => {
        if (showCancelar){
            return (
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
            )
        }
    }

    return (
        <div className="modal modal-lg fade" id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id={modalId + "Label"}>{title}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    {body}
                </div>
                <div className="modal-footer">
                    {renderCancelar()}
                    <button type="button" className="btn btn-primary" onClick={handleClick}>{primaryMessage}</button>
                </div>
                </div>
            </div>
        </div>
    );
}

export function showModal(modalId){
    const element = document.getElementById(modalId);
    const modal = Modal.getInstance(element) || new Modal(element);
    modal.show();
}

export function hideModal(modalId){
    const element = document.getElementById(modalId);
    const modal = Modal.getInstance(element);
    modal.hide();
}