import { useEffect } from "react";

export default function useOutsideClick(ref, onClickCallback) {
    useEffect(() => {
        function handleClickOutside(event){
            if (ref.current && !ref.current.contains(event.target)){
                onClickCallback(ref);
            }
        }

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        }

    }, [ref, onClickCallback])
}