export function toDateInputValue(date){
    return date.toJSON().slice(0,10);
}

export const todayInput = () => {
    let date = new Date();
    return toDateInputValue(date);
}

export const firstMonthDayInput = () => {
    let date = new Date();
    date = new Date(date.getFullYear(), date.getMonth(), 1);
    return toDateInputValue(date);
}

export const lastMonthDayinput = () => {
    let date = new Date();
    date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return toDateInputValue(date);
}

export const meses = {
    1: "Enero",
    2: "Febrero",
    3: "Marzo",
    4: "Abril",
    5: "Mayo",
    6: "Junio",
    7: "Julio",
    8: "Agosto",
    9: "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre",
}