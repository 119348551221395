import { useAppContext } from "../context.js";

import "./_Header.css";

export default function Header(props) {
    const context = useAppContext();
    const {appName, logout, toogleSidebar} = props;

    const handleOnClick = (e) => {
        e.stopPropagation();
        toogleSidebar();
    }

    return (
        <header id="header" className="header d-flex flex-nowrap align-items-center">
            {/* Menú */}
            <div className="col col-auto ps-0">
                <button className="btn sidebar-toogler" type="button" onClick={handleOnClick}>
                    <i className="bi bi-list h2 text-white"></i>
                </button>
            </div>
            {/* Logo ADO */}
            <div className="col col-auto d-flex align-items-center">
                <img src="/img/logo_mobility_blanco.png" height="32px"/>
            </div>
            {/* Usuario */}
            <div className="col d-flex align-items-center justify-content-end">
                <img src="/img/icono_user.png" height="32px" className="px-4"/>
                <div className="align-items-center h4 mb-0 pe-4">
                    {context.jwt.entity.nombre + ' ' + context.jwt.entity.apellido}
                </div>
            </div>
        </header>
    );
}