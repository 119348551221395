import { useState } from "react";
import "./_Login.css";

export default function Login(props){
    const {authenticate} = props;
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [fails, setFails] = useState(0);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const authenticated = await authenticate(username, password);
        if (!authenticated){
            setFails(fails => fails + 1);
        }
    }

    return(
        <div id="loginBackground">
            <div className="container-fluid full-height">
                <div className="row" id="loginBody">
                    <div className="col d-flex align-items-center justify-content-end">
                        <img src="/img/logo_inicio_sesion.png" width="250px"/>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center">
                        <form id="loginForm" onSubmit={handleSubmit}>
                        <div className="form-group py-1">
                            <label htmlFor="loginUsername" className="text-white">Usuario</label>
                            <input
                                id="loginUsername"
                                type="text"
                                className="form-control"
                                required
                                onChange={e => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="form-group py-1">
                            <label htmlFor="loginPassword" className="text-white">Contraseña</label>
                            <input
                                id="loginPassword"
                                type="password"
                                className="form-control"
                                required
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="d-flex justify-content-center pt-3">
                            <button type="submit" className="btn btn-rojo" id="loginButton">Iniciar sesión</button>
                        </div>
                        </form>
                    </div>
                </div>
                <div className="row" id="loginFooter">
                    <div className="col text-center">
                        <h2 id="loginFooterTitle">Gestión de órdenes de servicio</h2>
                        <h5 id="loginFooterSubtitle">
                            SEDENA
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    )
}