import Situacion from "./Situacion";

export default class OrdenServicio{
    #numorden;
    #oficio_regional_id;
    #fecha_venta;
    #fecha_viaje;
    #terminal_origen_id;
    #terminal_destino_id;
    #marca_id;
    #region_id;
    #pais_id;
    #situacion_id;
    #precio;
    #boletos;
    #subtotal;
    #iva;
    #total;
    #dependencia_id;
    #transportista_id;
    #dependencia;
    #marca;
    #region;
    #region_clave;
    #situacion;
    #terminal_origen;
    #terminal_destino;
    #transportista;
    #transportista_clave;

    constructor(entity){
        this.#numorden = entity.numorden;
        this.#oficio_regional_id = entity.oficio_regional_id;
        this.#fecha_venta = entity.fecha_venta;
        this.#fecha_viaje = entity.fecha_viaje;
        this.#terminal_origen_id = entity.terminal_origen_id;
        this.#terminal_destino_id = entity.terminal_destino_id;
        this.#marca_id = entity.marca_id;
        this.#region_id = entity.region_id;
        this.#pais_id = entity.pais_id;
        this.#situacion_id = entity.situacion_id;
        this.#precio = entity.precio;
        this.#boletos = entity.boletos;
        this.#subtotal = entity.subtotal;
        this.#iva = entity.iva;
        this.#total = entity.total;
        this.#dependencia_id = entity.dependencia_id;
        this.#transportista_id = entity.transportista_id;
        this.#dependencia = entity.dependencia;
        this.#marca = entity.marca;
        this.#region = entity.region;
        this.#region_clave = entity.region_clave;
        this.#situacion = entity.situacion;
        this.#terminal_origen = entity.terminal_origen;
        this.#terminal_destino = entity.terminal_destino;
        this.#transportista = entity.transportista;
        this.#transportista_clave = entity.transportista_clave;
    }

    get numorden(){
        return this.#numorden;
    }
    get oficio_regional_id(){
        return this.#oficio_regional_id;
    }
    set oficio_regional_id(value){
        this.#oficio_regional_id = value;
    }
    get fecha_venta(){
        return this.#fecha_venta;
    }
    get fecha_viaje(){
        return this.#fecha_viaje;
    }
    get terminal_origen_id(){
        return this.#terminal_origen_id;
    }
    get terminal_destino_id(){
        return this.#terminal_destino_id;
    }
    get marca_id(){
        return this.#marca_id;
    }
    get region_id(){
        return this.#region_id;
    }
    get pais_id(){
        return this.#pais_id;
    }
    get situacion_id(){
        return this.#situacion_id;
    }
    set situacion_id(value){
        this.#situacion_id = value;
        this.#situacion = Situacion.Situaciones[value].descripcion;
    }
    get precio(){
        return this.#precio;
    }
    get boletos(){
        return this.#boletos;
    }
    get subtotal(){
        return this.#subtotal;
    }
    get iva(){
        return this.#iva;
    }
    get total(){
        return this.#total;
    }
    get dependencia_id(){
        return this.#dependencia_id;
    }
    get transportista_id(){
        return this.#transportista_id;
    }

    get dependencia(){
        return this.#dependencia;
    }

    get marca(){
        return this.#marca;
    }

    get region(){
        return this.#region;
    }

    get region_clave(){
        return this.#region_clave;
    }

    get situacion(){
        return this.#situacion;
    }

    get terminal_origen(){
        return this.#terminal_origen;
    }

    get terminal_destino(){
        return this.#terminal_destino;
    }

    get transportista(){
        return this.#transportista;
    }

    get transportista_clave(){
        return this.#transportista_clave;
    }

}