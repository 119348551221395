import { Outlet } from "react-router-dom";
import Header from "./_Header";
import Footer from "./_Footer";
import NavMenu from "./_NavMenu";
import "./_Layout.css";
import { useRef, useState } from "react";
import useOutsideClick from "../components/useOutsideClick";

export default function Layout(props){
    const {appName, logout} = props;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const sidebar = useRef(null);
    useOutsideClick(sidebar, (ref) => {
        setIsMenuOpen(false);
    });

    // const toogleIsMenuOpen = () => {
    //     setIsMenuOpen(isMenuOpen => {
    //         // if (!isMenuOpen){
    //         //     sidebar.current.focus();
    //         // }else{
    //         //     sidebar.current.blur();
    //         // }

    //         return !isMenuOpen;
    //     });
    // }

    const sidebarStyle = (isMenuOpen) => {
        if (isMenuOpen){
            return {
                'boxShadow': '0.5rem 0.25rem 1rem -0.25rem gray'
            }
        }
        return {
            'boxShadow': 'none'
        }
    }

    const handleSidebarOnClick = () => {
        setIsMenuOpen(true);
    }

    return (
        <div id="layout" className="layout">
            <Header appName={appName} logout={logout} toogleSidebar={() => setIsMenuOpen(prev => !prev)}/>
            <div id="body">
                <aside
                    ref={sidebar}
                    id="sidebar"
                    className="sidebar col-auto p-0 d-flex flex-column justify-content-center"
                    style={sidebarStyle(isMenuOpen)}
                    onClick={handleSidebarOnClick}
                >
                    <NavMenu isMenuOpen= {isMenuOpen}/>
                </aside>
                <main id="main" role="main" className="col p-1 main">
                    <Outlet/>
                </main>
            </div>
            <Footer/>
        </div>
    );
}