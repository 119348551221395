import "./GestionBase.css";

export default function GestionBase(props){
    const {title, children, subtitle, image, filtro, table, totales} = props;

    return (
        <div id="gestion-base">
            <div id="gestion-base-header">
                <div className="row align-items-center">
                    <div className="col">
                        <h2 id="gestion-base-title">
                            {title}
                        </h2>
                        <h4 id="gestion-base-subtitle">
                            {subtitle}
                        </h4>
                    </div>
                    <div className="col col-auto justify-content-end">
                        <img src={image} />
                    </div>
                </div>
            </div>
            <div id="gestion-base-filter">
                    {filtro}
            </div>
            <div id="gestion-base-body">
                {table}
            </div>
            <div id="gestion-base-children">
                {children}
            </div>
            <div id="gestion-base-footer">
                {totales}
            </div>
        </div>
    );
}