import { useRef } from "react";
import FiltroColumna from "../components/FiltroColumna";

export default function PeriodoSelector (props){
    const {
        fechaIni,
        setFechaIni,
        fechaFin,
        setFechaFin,
    } = props;

    const fechaIniRef = useRef(null);
    const fechaFinRef = useRef(null);

    const handleChangeFechaIni = () => {
        setFechaIni(fechaIniRef.current.value)
    }

    const handleChangeFechaFin = () => {
        setFechaFin(fechaFinRef.current.value)
    }

    return (
        <div className="row">
            <FiltroColumna title={"Fecha inicial"} flexLgCol={6}>
                <input
                    ref={fechaIniRef}
                    type="date"
                    className="ps-1 pe-1 full-width dropdown-selector"
                    defaultValue={fechaIni}
                    onChange={handleChangeFechaIni}
                ></input>
            </FiltroColumna>
            <FiltroColumna title={"Fecha final"} flexLgCol={6}>
                <input
                    ref={fechaFinRef}
                    type="date"
                    className="ps-1 pe-1 full-width dropdown-selector"
                    defaultValue={fechaFin}
                    onChange={handleChangeFechaFin}
                ></input>
            </FiltroColumna>
        </div>
    )
}