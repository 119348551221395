import Dependencia from "../../models/Dependencia";
// import { GoogleAuth } from 'google-auth-library';
// const auth = new GoogleAuth();

const url = process.env.REACT_APP_BACKEND_URL;
// const targetAudience = 'http://127.0.0.1:3000';

export async function dependencia_get(
    params
){
    let query = url + '/api/dependencia';
    if (Object.keys(params).length >= 0)
        query += '?' + new URLSearchParams(params);

    // const client = await auth.getIdTokenClient(targetAudience);
    // const response = await client.request({query}, {
    const response = await fetch(query, {
        method: 'GET',
    }).then(async response => {
        if(response.ok){
            return response.json().then(
                payload => {
                    const result = [];
                    payload.data.forEach(entity => {
                        result.push(new Dependencia(entity))
                    });
                    return {
                        ok: true,
                        data: result,
                    };
                }
            )
        }else{
            return response.text().then(
                payload => {
                    return {
                        ok: false,
                        data: payload
                    }
                }
            )
        }
    }).catch(reason => {
        console.log('Error al invocar servicio /api/dependencia ' + reason);
    })

    return response;
}