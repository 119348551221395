import { useEffect, useState } from "react";
import { useAppContext } from "../context.js";

export default function Home(){
    const context = useAppContext();
    const [user, setUser] = useState(null);

    useEffect(() =>{
        const _testToken = async () => {
            const user = context.jwt;
            setUser(user);
        }
        _testToken();
    }, [context.token]);

    const renderUser = (user) => {
        const props = [];
        Object.keys(user).forEach((keyName, i) => {
            let value = user[keyName];

            if (typeof value === 'boolean')
                value = String(value);

            props.push(
                <tr key={i}>
                    <td>{keyName}</td>
                    <td>{value}</td>
                </tr>
            )
        });

        return (
            <table className="table table-sm">
                <caption>Current user authorization token</caption>
                <tbody>
                    {props}
                </tbody>
            </table>
        );
    }

    if (user){
        // const expires = new Date(context.jwt.exp * 1000);

        return (
            <div className="full-width-height">
                <div className="main-header">
                    <h3 className="text-center">Authentication token</h3>
                </div>
                <div className="main-body">
                    {/* <div className="row g-0">
                        <div className="col col-4 font-weight-bold">
                            Issuer
                        </div>
                        <div className="col">
                            {context.jwt.iss}
                        </div>
                    </div>
                    <div className="row g-0">
                        <div className="col col-4 font-weight-bold">
                            Expires
                        </div>
                        <div className="col">
                            {expires.toString()}
                        </div>
                    </div> */}
                    <div className="row g-0">
                        <div className="col">
                            {renderUser(context.jwt.entity)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return(
        <div className='center'>
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Cargando...</span>
            </div>
        </div>
    )
}