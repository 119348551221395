export default function FiltroColumna(props){
    const {title, children, flexLgCol= 4, flexSubLgCol1=5, flexSubLgCol2=7} = props;

    return (
        <div className={"col-12 col-md-6 col-lg-" + flexLgCol.toString()}>
            <div className="row g-0">
                <div className={"col col-sm-5 col-md-5 text-right col-lg-" + flexSubLgCol1.toString()} >
                    {title}
                </div>
                <div className={"col col-sm-7 col-md-7 ps-2 col-lg-" + flexSubLgCol2.toString()}>
                    {children}
                </div>
            </div>
        </div>
    );
}