import OficioRegional from "../../models/OficioRegional";

const url = process.env.REACT_APP_BACKEND_URL;

export async function oficioRegional_get(
    params
){
    let query = url + '/api/oficioregional';
    if (Object.keys(params).length >= 0)
        query += '?' + new URLSearchParams(params);

    const response = await fetch(query, {
        method: 'GET',
    }).then(async response => {
        if (response.status === 204){
            return {
                ok: false,
                data: 'Oficio no registrado en base de datos.'
            }
        }else if(response.ok){
            return response.json().then(
                payload => {
                    const result = [];
                    payload.data.forEach(entity => {
                        result.push(new OficioRegional(entity))
                    });
                    return {
                        ok: true,
                        data: result,
                    };
                }
            )
        }else{
            return {
                ok: false,
                data: response.text()
            };
        }
    }).catch(reason => {
        console.log('Error al invocar servicio /api/oficioregional ' + reason);
    })

    return response;
}

export async function oficioRegionalFolio_post(fecha, os_ids, dependencia_id, transportista_id){
    const endpoint = url + '/api/oficioregional/folio';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            fecha: fecha,
            os_ids: os_ids,
            dependencia_id: dependencia_id,
            transportista_id: transportista_id,
        }),
    }).then(async response =>  {
        if (response.ok){
            return response.json().then(payload => {
                let oficio = null;
                if (payload.oficio !== null){
                    oficio = new OficioRegional(payload.oficio);
                }

                return {
                    ok: oficio !== null,
                    oficio: oficio,
                    errors: payload.errors,
               };
            });
        }else{
            return response.text().then(text => {
                return {
                    ok: false,
                    data: text
                }
            })
        }
    });

    return response;
}

export async function oficioRegionalPeriodo_get(){
    const endpoint = url + '/api/oficioregional/periodo';
    const response = await fetch(endpoint, {
        method: 'GET',
    }).then(async response => {
        if (response.ok){
            return response.json().then(payload => {
                return {
                    ok: true,
                    data: payload
                };
            })
        }else{
            return response.text().then(text => {
                return {
                    ok: false,
                    data: text
                }
            });
        }
    });

    return response;
}