import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useState } from "react";
import { AppContextProvider } from "./context.js";

import Home from "./pages/Home.js";
import Region from "./pages/Region.js";
import Corporativo from "./pages/Corporativo.js";
import Admin from "./pages/Admin.js";
import Login from "./shared/_Login.js";
import Layout from "./shared/_Layout.js";

import { useCookies } from "react-cookie";

import "bootstrap-icons/font/bootstrap-icons.css";
import Buscador from "./pages/Buscador.js";

function App() {
  const [cookies, setCookies, removeCookie] = useCookies(["token"]);
  const [context, setContext] = useState(null);
  const [autenticado, setAutenticado] = useState(false);

  const router = createBrowserRouter([
    {
      element: <Layout appName= 'OS Sedena' logout={logout}/>,
      children: [
        {
          path: "/",
          element: <Home/>,
        },
        {
          path: "/region",
          element: <Region/>
        },
        {
          path: "/corporativo",
          element: <Corporativo/>
        },
        {
          path: "/admin",
          element: <Admin/>
        },
        {
          path: "/buscador",
          element: <Buscador/>
        },
      ],
    }
  ])

  async function authenticate(username, password) {
    // TODO: hacer autenticación con AD o backend
    if (username === 'ossedena' && password === 'password'){
      const jwt = 'jwt';
      login(jwt);
      setCookies("token", jwt, {
        path: "/"
      })
      return true;
    }
    return false;
  }

  function login(token){
    // const jwt = parseJwt(token);
    const jwt = {
      entity: {
        nombre: 'OS Sedena',
        apellido: 'Test User',
        email: 'ossedena@mobilityado.com',
        login: 'ossedena'
      }
    };
    setContext({
      token: token,
      jwt: jwt
    })
    setAutenticado(true);
    return jwt;
  }

  function logout(){
    removeCookie("token");
    setContext(null);
    setAutenticado(false);
  }

  if (!autenticado){
    if (cookies.token){
      login(cookies.token)
      setAutenticado(true);
    }
    else{
      return(
        <Login authenticate = {authenticate} />
      );
    }
  }

  return (
    <div className="App">
      <AppContextProvider value = {context}>
        <RouterProvider router={router} />
      </AppContextProvider>
    </div>
  );
}

export default App;
