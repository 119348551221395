import Situacion from "./Situacion";

export default class OficioRegional{
    #id;
    #oficio_corporativo_id;
    #fecha;
    #anio;
    #mes;
    #consecutivo;
    #precio;
    #subtotal;
    #iva;
    #total;
    #situacion_id;

    constructor(entity){
        this.#id = entity.id;
        this.#oficio_corporativo_id = entity.oficio_corporativo_id;
        this.#fecha = entity.fecha;
        this.#anio = entity.anio;
        this.#mes = entity.mes;
        this.#consecutivo = entity.consecutivo;
        this.#precio = entity.precio;
        this.#subtotal = entity.subtotal;
        this.#iva = entity.iva;
        this.#total = entity.total;
        this.#situacion_id = entity.situacion_id;
    }

    get id(){
        return this.#id;
    }
    get oficio_corporativo_id(){
        return this.#oficio_corporativo_id;
    }
    set oficio_corporativo_id(value){
        this.#oficio_corporativo_id = value;
    }
    get fecha(){
        return this.#fecha;
    }
    get anio(){
        return this.#anio;
    }
    get mes(){
        return this.#mes;
    }
    get consecutivo(){
        return this.#consecutivo;
    }
    get precio(){
        return this.#precio;
    }
    get subtotal(){
        return this.#subtotal;
    }
    get iva(){
        return this.#iva;
    }
    get total(){
        return this.#total;
    }
    get situacion_id(){
        return this.#situacion_id;
    }

    set situacion_id(value){
        this.#situacion_id = value;
    }

    get situacion(){
        return Situacion.Situaciones[this.#situacion_id].descripcion;
    }
}