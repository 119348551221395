import { useEffect, useState } from "react";
import "./List.css";
import FiltroColumna from "./FiltroColumna";

export default function List(props){
    const {name, entities, setSelected, initialIndex = 0, ...rest} = props;

    const [value, setValue] = useState('');

    useEffect(() => {
        if (entities.length > 0){
            setSelected(entities[initialIndex].id);
            setValue(entities[initialIndex].id);
        }

    }, [entities]);

    const handleChange = (event) => {
        setValue(event.target.value);
        setSelected(event.target.value);
        // const selected = indexedEntities.get(event.target.value);
        // setSelected(selected);
    }

    const renderList = () => {
        const list = [];

        if (entities.length === 0){
            list.push(
                <option key={name + '_0'} value='0'>
                    Loading...
                </option>
            )
        }

        entities.forEach(entity => {
            list.push(
                <option key={name + '_' + entity.id.toString()} value={entity.id}>
                    {entity.descripcion}
                </option>
            )
        });

        return list;
    }

    return (
        <FiltroColumna title={name} {...rest}>
            <select id={"list-" + name} className="full-width selector" value={value} onChange={handleChange} dir="">
                {renderList()}
            </select>
        </FiltroColumna>
    )
}