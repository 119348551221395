import { useRef, useState } from "react";
import "./Filtro.css";
import { useEffect } from "react";
import MultiList from "./MiltiList";
import List from "./List";
import Divider from "./Divider";
import Spacer from "./Spacer";
import FiltroColumna from "./FiltroColumna";
import { dependencia_get } from "../services/Api/Dependencia";
import { transportista_get } from "../services/Api/Transportista";
import { region_get } from "../services/Api/Region";
import { ModalElement, showModal } from "./Modal";
import Situacion from "../models/Situacion";
import { firstMonthDayInput, lastMonthDayinput, toDateInputValue } from "../util/Fecha";
import PeriodoSelector from "./PeriodoSelector";

export default function Filtro(props){
    const {
        fecha,
        setFecha,
        numeroOficio,
        setNumeroOficio,
        buscar,
        generarFolio,
        generarFolioDisabled,
        generandoFolio,
        limpiar,
        pdfDownloadUrl,
        excelDownloadUrl,
        // periodos = null,
        // añoSelected = null,
        // setAñoSelected = null,
        // mesSelected = null,
        // setMesSelected = null,
        esRegion = true, // false: es Corporativo
    } = props;
    const errorModalId = "modal-filtro-error";

    const [dependencias, setDependencias] = useState([]);
    const [regiones, setRegiones] = useState([])
    const [situaciones, setSituaciones] = useState([Situacion.Enum.RECIBIDA, Situacion.Enum.ENVIADA, Situacion.Enum.POR_COBRAR]);
    const [transportistas, setTransportistas] = useState([]);

    const [regionesSelected, setRegionesSelected] = useState(new Set());
    const [dependenciaSelected, setDependenciaSelected] = useState('');
    const [transportistaSelected, setTransportistaSelected] = useState('');
    const [situacionSelected, setSituacionSelected] = useState('');
    const [fechaIni, setFechaIni] = useState(firstMonthDayInput());
    const [fechaFin, setFechaFin] = useState(lastMonthDayinput());
    // const [añoSelected, setAñoSelected] = useState('');
    // const [mesSelected, setMesSelected] = useState('');

    const [buscarDisabled, setBuscarDisabled] = useState(true);
    const [descargarDisable, setDescargarDisabled] = useState(true);

    const [buscarLeyenda, setBuscarLeyenda] = useState('Buscar');

    const [errorBody, setErrorBody] = useState('');

    const fechaRef = useRef(null);
    const numeroOficioRef = useRef(null);

    useEffect(() => {
        dependencia_get({
            all: true,
            page_size: 0,
            columns_order: 'descripcion',
            columns_sort: 'asc',
        }).then(result => {
            if (result.ok){
                setDependencias(result.data);
            }
            else{
                setErrorBody(result.data);
                showModal(errorModalId);
            }
        })

        transportista_get({
            all: true,
            page_size: 0,
            columns_order: 'descripcion',
            columns_sort: 'asc',
        }).then(result => {
            if (result.ok){
                setTransportistas(result.data);
                setTransportistaSelected(result.data[0]);
            }
            else{
                setErrorBody(result.data);
                showModal(errorModalId);
            }
        })

        region_get({
            all: true,
            page_size: 0,
            columns_order: 'descripcion',
            columns_sort: 'asc',
        }).then(result => {
            if (result.ok){
                setRegiones(result.data);
            }
            else{
                setErrorBody(result.data);
                showModal(errorModalId);
            }
        })

        // situacion_get({
        //     all: true,
        //     page_size: 0,
        // }).then(result => {
        //     if (result.ok){
        //         setSituaciones(result.data);
        //         setSituacionSelected(result.data[0]);
        //     }
        //     else{
        //         setErrorBody(result.data);
        //         showModal(errorModalId);
        //     }
        // })
    }, []);

    useEffect(() => {
        if (
            numeroOficio !== '' || (
                regionesSelected.size > 0
                && dependenciaSelected !== ''
                && transportistaSelected !== ''
                && situacionSelected !== ''
            )
        ){
            setBuscarDisabled(false);
        }else{
            setBuscarDisabled(true);
        }
    }, [numeroOficio, regionesSelected, dependenciaSelected, transportistaSelected, situacionSelected]);

    // useEffect(() => {
    //     if (numeroOficio !== ''){
    //         setDescargarDisabled(false);
    //     }else{
    //         setDescargarDisabled(true)
    //     }
    // }, [numeroOficio])

    const toggleRegionSelected = (regionId) => {
        setRegionesSelected(selected => {
            if (selected.has(regionId))
                selected.delete(regionId);
            else
                selected.add(regionId);

            let newSelected = new Set(selected);
            return newSelected;
        });
    }

    const handleChangeFecha = () => {
        setFecha(fechaRef.current.value);
    }

    const handleChangeFolio = () => {
        setNumeroOficio(numeroOficioRef.current.value);

        if (numeroOficioRef.current.value !== ''){
            setBuscarLeyenda('Buscar oficio');
        }
        else{
            setBuscarLeyenda('Buscar');
        }
    }

    const handleBuscar = (e) => {
        e.preventDefault()
        buscar(numeroOficio, regionesSelected, dependenciaSelected, transportistaSelected, situacionSelected, fechaIni, fechaFin);
    }

    const handleLimpiar = () => {
        setRegionesSelected(new Set());
        setBuscarLeyenda('Buscar');
        limpiar();
    }

    const handleGenerarFolio = () => {
        const [region_id] = regionesSelected;

        generarFolio(dependenciaSelected, transportistaSelected, region_id);
    }

    const renderGenerandoFolio = () => {
        if (generandoFolio){
            return(
                <div className="spinner-border spinner-border-sm text-primary" role="status">
                    <span className="visually-hidden">Cargando...</span>
                </div>
            )
        }
    }

    const renderNoOficioFilter = (flexLgCol, flexSubLgCol1, flexSubLgCol2) => (
    <FiltroColumna title={"No. de oficio"}
        flexLgCol={flexLgCol}
        flexSubLgCol1={flexSubLgCol1}
        flexSubLgCol2={flexSubLgCol2}
    >
        <div className="row g-0 justify-content-around">
            <div className="col ">
                <div className=" text-center numero-oficio">
                    <input
                        id="filtro-folio-input"
                        className="w-100"
                        ref={numeroOficioRef}
                        onChange={handleChangeFolio}
                        value={numeroOficio}
                    >
                    </input>
                </div>
            </div>
        </div>
    </FiltroColumna>)

    const renderSituacionSelector = (flexLgCol, flexSubLgCol1, flexSubLgCol2 ) => (
    <List name={'Situación'}
        entities={situaciones}
        selected={situacionSelected}
        setSelected={setSituacionSelected}
        initialIndex={esRegion ? 0 : 1}
        flexLgCol={flexLgCol}
        flexSubLgCol1={flexSubLgCol1}
        flexSubLgCol2={flexSubLgCol2}
    />)

    const renderDependenciaSelector = (flexLgCol, flexSubLgCol1, flexSubLgCol2) => (
    <List name={"Dependencia"}
        entities={dependencias}
        setSelected={setDependenciaSelected}
        flexLgCol={flexLgCol}
        flexSubLgCol1={flexSubLgCol1}
        flexSubLgCol2={flexSubLgCol2}
    />)

    const renderRegionSelector = (flexLgCol, flexSubLgCol1, flexSubLgCol2) => (
    <MultiList name={"Región"}
        entities={regiones}
        selected = {regionesSelected}
        toggleSelected={toggleRegionSelected}
        flexLgCol={flexLgCol}
        flexSubLgCol1={flexSubLgCol1}
        flexSubLgCol2={flexSubLgCol2}
    />)

    const renderBuscarLimpiar = (flexLgCol = 3) => {
    let className = "col-md-4 d-flex col-lg-"  + flexLgCol.toString()

    if (esRegion){
        className += ' offset-lg-' + (6 - flexLgCol).toString()
    }

    return (
    <div className={className}>
        {/* Buscar */}
        <div className="col col-6 g-0">
            <button
                id="buscar-oficio"
                className="btn-filter"
                type="submit"
                disabled={buscarDisabled}
            >
                <i className="bi bi-search"></i> {buscarLeyenda}
            </button>
        </div>
        {/* Limpiar */}
        <div className="col col-6 g-0 ps-1">
            <button
                id="limpiar-filtro"
                className="btn-filter"
                onClick={handleLimpiar}
            >
                <img src="/img/icono_limpiar.png" height={"16px"}/> Limpiar
            </button>
        </div>
    </div>)
    }
    const renderPeriodoSelector = (flexLgCol = 4) => (
    <div className={"col offset-lg-1 col-lg-" + flexLgCol.toString()}>
        <PeriodoSelector
            fechaIni={fechaIni}
            setFechaIni={setFechaIni}
            fechaFin={fechaFin}
            setFechaFin={setFechaFin}
        />
    </div>
    )

    const renderGenerarFolio = (flexLgCol = 2) => {
        let className = "col-md-2 col-lg-"  + flexLgCol.toString()

        if (!esRegion){
            className += ' offset-lg-' + (8 - flexLgCol).toString()
        }else{
            className += ' offset-lg-' + (5 - flexLgCol).toString()
        }

        return (
        <div className={className}>
            <button
                id="generar-folio"
                className="btn-filter btn-generar-folio"
                onClick={handleGenerarFolio}
                disabled={generarFolioDisabled}
            >
                <img src="/img/icono_generar.png" height={"16px"}/> Generar folio
                {renderGenerandoFolio()}
            </button>
        </div>)
    }

    const renderDescargar = () => {
        if (descargarDisable)
            return;

        return (
            <div className="col-auto row">
                <div className="col-auto ps-1">
                    <form
                        action={pdfDownloadUrl + numeroOficio}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button
                            type="submit"
                            className="btn-pdf"
                            title="Descargar pdf"
                            disabled={descargarDisable}
                        >
                            <i className="bi bi-filetype-pdf"></i>
                        </button>
                    </form>
                </div>
                <div className="col-auto ps-1">
                    <form
                        action={excelDownloadUrl + numeroOficio}
                        target="_blank"
                    >
                        <button
                            type="submit"
                            className="btn-excel"
                            title="Descargar excel"
                            disabled={descargarDisable}
                        >
                            <i className="bi bi-file-earmark-excel"></i>
                        </button>
                    </form>
                </div>
            </div>
        )
    }

    // const handleAñoChange = (event) => {
    //     setAñoSelected(event.target.value);
    // }

    // const handleMesSelected = (event) => {
    //     setMesSelected(event.target.value)
    // }

    // const renderAño = () => {
    //     if (periodos){
    //         const renderList = () => {
    //             const list = [];
    //             Object.keys(periodos).forEach(año => {
    //                 list.push(
    //                     <option key={"año_" + año} value={año}>
    //                         {año}
    //                     </option>
    //                 );
    //             });

    //             return list;
    //         }

    //         return(
    //             <FiltroColumna title="Año" flexLgCol={2}>
    //                 <select id="list-año" className="full-width selector" value={añoSelected} onChange={handleAñoChange}>
    //                     {renderList()}
    //                 </select>
    //             </FiltroColumna>
    //         );
    //     }
    // }

    // const renderMes = () => {
    //     if (periodos){
    //         const renderList = () => {
    //             const list = [];

    //             if (añoSelected !== ''){
    //                 periodos[añoSelected].forEach(mes => {
    //                     list.push(
    //                         <option key={"mes_" + mes} value={mes}>
    //                             {meses[mes]}
    //                         </option>
    //                     );
    //                 });
    //             }

    //             return list;
    //         }

    //         return(
    //             <FiltroColumna title="Periodo" flexLgCol={3}>
    //                 <select id="list-mes" className="full-width selector" value={mesSelected} onChange={handleMesSelected}>
    //                     {renderList()}
    //                 </select>
    //             </FiltroColumna>
    //         );
    //     }
    // }

    const renderTransportista = (flexLgCol, flexSubLgCol1, flexSubLgCol2) => (
    <List
        name={"Transportista"}
        entities={transportistas}
        selected={transportistaSelected}
        setSelected={setTransportistaSelected}
        flexLgCol={flexLgCol} flexSubLgCol1={flexSubLgCol1} flexSubLgCol2={flexSubLgCol2}
    />)

    const renderTransportistaFilter = (...props) => {
        if (!esRegion){
            return renderTransportista(...props);
        };
    }

    const renderTransportistaSelector = (...props) => {
        if (esRegion){
            return renderTransportista(...props);
        };
    }

    const renderFechaOficio = (flexLgCol, flexSubLgCol1, flexSubLgCol2) => (
    <FiltroColumna title={"Fecha de oficio"} flexLgCol={flexLgCol} flexSubLgCol1={flexSubLgCol1} flexSubLgCol2={flexSubLgCol2}>
    <input
        ref={fechaRef}
        type="date"
        className="ps-1 pe-1 full-width dropdown-selector"
        defaultValue={fecha}
        onChange={handleChangeFecha}
    ></input>
    </FiltroColumna>)

    return (
        <div id="filter-wrapper">
            <form onSubmit={handleBuscar}>
                {/* Apartado de búsqueda */}
                {/* 1er renglón*/}
                <div className="filter row">
                    <div className="col h6 filter-title col-12 col-md-6 col-lg-1">
                        Búsqueda
                    </div>
                    {renderNoOficioFilter(3, 4, 8)}
                    {renderSituacionSelector(2,3 ,9)}
                    {renderDependenciaSelector(3,3,9)}
                    {renderRegionSelector(3,3,9)}
                </div>
                <div className="filter row">
                    {renderPeriodoSelector(5)}
                    {renderTransportistaFilter(3,3,9)}
                    {renderBuscarLimpiar(3)}
                </div>
                <Divider/>
                {/* Apartado de datos para oficio */}
                <div className="filter row justify-content-between">
                    <div className="h6 filter-title col-12 col-md-6 col-lg-1">
                        Datos Oficio
                    </div>
                    {renderDescargar()}
                    {renderFechaOficio(3,4,8)}
                    {renderTransportistaSelector(3,3,9)}
                    {renderGenerarFolio(3)}
                </div>
            </form>
            <ModalElement modalId= {errorModalId} body={errorBody} showCancelar={false}/>
        </div>
    );
}