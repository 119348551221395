import Common from "./Common";

export default class Region extends Common{
    #clave;

    constructor(entity){
        super(entity);
         this.#clave = entity.clave;
    }

    get clave(){
        return this.#clave;
    }
}