import Region from "../../models/Region"

const url = process.env.REACT_APP_BACKEND_URL;

export async function region_get(
    params
){
    let query = url + '/api/region';
    if (Object.keys(params).length >= 0)
        query += '?' + new URLSearchParams(params);

    const response = await fetch(query, {
        method: 'GET',
    }).then(async response => {
        if(response.ok){
            return response.json().then(
                payload => {
                    const result = [];
                    payload.data.forEach(entity => {
                        result.push(new Region(entity))
                    });
                    return {
                        ok: true,
                        data: result,
                    };
                }
            )
        }else{
            return response.text().then(
                payload => {
                    return {
                        ok: false,
                        data: payload
                    }
                }
            )
        }
    }).catch(reason => {
        console.log('Error al invocar servicio /api/region ' + reason);
    })

    return response;
}