import { dineroFormatter } from "../util/StringFormat";

import "./Total.css";

export default function Total(props){
    const {totales, ...rest} = props;


    return(
        <div className="total row flex-nowrap g-0 justify-content-between">
            <div className="col-auto">
                <div className="row g-0">
                    <div className="col-auto pe-1">
                        Total de órdenes
                    </div>
                    <div className="col-auto">
                        {totales.nOrdenes}
                    </div>
                </div>
            </div>
            <div className="col-auto">
                <div className="row g-0">
                    <div className="col-auto pe-1">
                    Importe órdenes
                    </div>
                    <div className="col-auto">
                    {dineroFormatter.format(totales.importe)}
                    </div>
                </div>
            </div>
            {/* <div className="col-auto">
                <div className="row g-0">
                    <div className="col-auto pe-1">
                    Importe aclaración
                    </div>
                    <div className="col-auto">
                    &nbsp;
                    </div>
                </div>
            </div> */}
            <div className="col-auto">
                <div className="row g-0">
                    <div className="col-auto pe-1">
                    Sub-total
                    </div>
                    <div className="col-auto">
                    {dineroFormatter.format(totales.subtotal)}
                    </div>
                </div>
            </div>
            <div className="col-auto">
                <div className="row g-0">
                    <div className="col-auto pe-1">
                    Total IVA
                    </div>
                    <div className="col-auto">
                    {dineroFormatter.format(totales.iva)}
                    </div>
                </div>
            </div>
            <div className="col-auto">
                <div className="row g-0">
                    <div className="col-auto pe-1">
                    Total
                    </div>
                    <div className="col-auto">
                    {dineroFormatter.format(totales.total)}
                    </div>
                </div>
            </div>
        </div>
    );
}