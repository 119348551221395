import Common from "./Common";

export default class Situacion extends Common{
    constructor(entity){
        super(entity)
    }

    static Enum = {
        RECIBIDA: new Situacion({id: 1, descripcion: "Recibida", activo: true}),
        ENVIADA: new Situacion({id: 2, descripcion: "Enviada", activo: true}),
        POR_COBRAR: new Situacion({id: 3, descripcion: "Por cobrar", activo: true}),
    }

    static Situaciones = {
        1: this.Enum.RECIBIDA,
        2: this.Enum.ENVIADA,
        3: this.Enum.POR_COBRAR,
    }
}