import Common from "./Common";

export default class Transportista extends Common{
    #rfc;
    #calle;
    #numero;
    #colonia;
    #alcaldia;
    #cp;
    #ciudad;

    constructor(entity){
        super(entity);
        this.#rfc = entity.clave;
        this.#calle = entity.calle;
        this.#numero = entity.numero;
        this.#colonia = entity.colonia;
        this.#alcaldia = entity.alcaldia;
        this.#cp = entity.cp;
        this.#ciudad = entity.ciudad;
    }

    get clave(){
        return this.#rfc;
    }

    get calle(){
        return this.#calle;
    }

    get numero(){
        return this.#numero;
    }

    get colonia(){
        return this.#colonia;
    }

    get alcaldia(){
        return this.#alcaldia;
    }

    get cp(){
        return this.#cp;
    }

    get ciudad(){
        return this.#ciudad;
    }
}