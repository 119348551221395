export default class OficioCorporativo{
    #id;
    #anio;
    #mes;
    #consecutivo;
    #transportista_id;
    #region_id;
    #fecha;

    constructor(entity){
        this.#id = entity.id;
        this.#anio = entity.anio;
        this.#mes = entity.mes;
        this.#consecutivo = entity.consecutivo;
        this.#transportista_id = entity.transportista_id;
        this.#region_id = entity.region_id;
        this.#fecha = entity.fecha;
    }

    get id(){
        return this.#id;
    }

    get anio(){
        return this.#anio;
    }

    get mes(){
        return this.#mes;
    }

    get consecutivo(){
        return this.#consecutivo;
    }

    get transportista_id(){
        return this.#transportista_id;
    }

    get region_id(){
        return this.#region_id;
    }

    get fecha(){
        return this.#fecha;
    }
}