import OrdenServicio from "../../models/OrdenServicio"

const url = process.env.REACT_APP_BACKEND_URL;

export async function ordenServicio_get(params){
    let query = url + '/api/ordenservicio';
    if (Object.keys(params).length >= 0)
        query += '?' + new URLSearchParams(params);
    console.log(query);
    const response = await fetch(query, {
        method: 'GET',
    }).then(async response => {
        if(response.ok){
            if (response.status === 204){
                console.log('/api/ordenservicio returned No content');

                return {
                    ok: true,
                    data: []
                }
            }

            return response.json().then(
                payload => {
                    const result = [];
                    payload.data.forEach(entity => {
                        result.push(new OrdenServicio(entity))
                    });
                    return {
                        ok: true,
                        data: result,
                    };
                }
            ).catch(e => {
                return {
                    ok: false,
                    data: e,
                }
            })
        }else{
            return response.text().then( text => {
                return {
                    ok: false,
                    data: text
                };
            })
        }
    }).catch(reason => {
        console.log('Error al invocar servicio /api/ordenServicio ' + reason);
    })

    return response;
}